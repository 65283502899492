import type {
  FlexContactSerializer,
  FlexServiceCenterSerializer,
  FlexTaskSerializer,
  FlexTaskStepSerializer,
  FlexTaskTypeSerializer,
  FlexTicketCommentSerializer,
  FlexTicketSerializer,
  FlexTicketVersionSerializer,
  FlexUserSerializer,
  FlexMemberCodesSerializer,
  FlexTaskCommentSerializer,
  PositiveResponseSerializer,
} from "@/utils/damage-prevention";
import type { DamagePotential, ImpactPotential } from "./TicketVersion";
import { PositiveResponse } from "./PositiveResponse";

type FlexTicketQueryFields =
  | keyof Omit<FlexTicketSerializer, "latest_version">
  | `latest_version.${keyof FlexTicketVersionSerializer}`
  | `latest_version.contacts.${keyof FlexContactSerializer}`
  | `service_center.${keyof FlexServiceCenterSerializer}`
  | `service_centers.${keyof FlexServiceCenterSerializer}`
  | `tasks.${keyof Omit<FlexTaskSerializer, "task_type" | "task_step">}`
  | `tasks.task_type.${keyof FlexTaskTypeSerializer}`
  | `tasks.task_step.${keyof FlexTaskStepSerializer}`
  | `tasks.assignee.${keyof FlexUserSerializer}`
  | `tasks.latest_comment.${keyof FlexTaskCommentSerializer}`
  | `latest_comment.${keyof FlexTicketCommentSerializer}`
  | `member_codes_list.${keyof FlexMemberCodesSerializer}`
  | `positive_responses.${keyof PositiveResponseSerializer}`;

interface FlexTicket {
  id?: number;
  number?: string;
  stage?: string;
  latestVersion?: FlexTicketVersion;
  serviceCenter?: FlexServiceCenter;
  serviceCenters?: FlexServiceCenter[];
  latestComment?: FlexTicketComment;
  tasks?: FlexTask[];
  memberCodesList?: FlexMemberCodes[];
  createTime?: Date;
  isEmergency?: boolean;
  expires_on?: Date;
  positiveResponses?: PositiveResponse[];
  isPrefetched?: boolean;
}

interface FlexMemberCodes {
  id?: number;
  key?: string;
}

interface FlexTask {
  id?: number;
  sequence?: number;
  createTime?: Date;
  completedTime?: Date;
  taskType?: FlexTaskType;
  taskStep?: FlexTaskStep;
  assignee?: FlexUser;
  latestComment?: FlexTaskComment;
}

interface FlexUser {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  is_active?: boolean;
}

interface FlexTaskType {
  id?: number;
  name?: string;
}

interface FlexTaskStep {
  id?: number;
  name?: string;
  initial?: boolean;
  terminating?: boolean;
}

interface FlexTaskComment {
  comment: string;
  created_at: Date;
  creator: FlexUserSerializer;
}

interface FlexServiceCenter {
  id?: number;
  name?: string;
}

interface FlexTicketComment {
  comment?: string;
}

interface FlexTicketVersion {
  contacts?: FlexContact[];
  damagePotential?: DamagePotential;
  equipmentType?: string;
  excavationWorkType?: string;
  excavationCity?: string;
  excavationCounty?: string;
  excavationStreetName?: string;
  excavationStreetNumber?: string;
  excavationState?: string;
  impactPotential?: ImpactPotential;
  locateRemarks?: string;
  type?: string;
  workDoneFor?: string;
  workStartDate?: Date;
  tlc_received_at?: Date;
  responseDueDate?: Date;
  reportedDate?: Date;
  crossStreet1?: string;
  crossStreet2?: string;
  scaled_impact_score?: number;
  scaled_threat_score?: number;
  zip_code?: string;
  description?: string;
  locate_area?: string;
  excavator_name?: string;
  excavator_phone_number?: string;
}
interface FlexContact {
  companyName?: string;
  onSite?: boolean;
  phoneNumber?: string;
  primary?: boolean;
}

const respToFlexContact = (resp: FlexContactSerializer): FlexContact => ({
  companyName: resp.company_name ?? undefined,
  primary: resp.primary ?? undefined,
  onSite: resp.on_site ?? undefined,
  phoneNumber: resp.phone_number ?? undefined,
});

const respToFlexTicketComment = (
  resp: FlexTicketCommentSerializer
): FlexTicketComment => ({
  comment: resp.comment ?? undefined,
});

const respToFlexTicketVersion = (
  resp: FlexTicketVersionSerializer
): FlexTicketVersion => {
  const { contacts } = resp as any;
  return {
    contacts: contacts ? contacts.map(respToFlexContact) : undefined,
    damagePotential: (resp.damage_potential as DamagePotential) ?? undefined,
    impactPotential: (resp.impact_potential as ImpactPotential) ?? undefined,
    equipmentType: resp.equipment_type ?? undefined,
    excavationWorkType: resp.excavation_work_type ?? undefined,
    excavationCity: resp.excavation_city ?? undefined,
    excavationStreetName: resp.excavation_street_name ?? undefined,
    excavationStreetNumber: resp.excavation_street_number ?? undefined,
    excavationState: resp.excavation_state ?? undefined,
    excavationCounty: resp.excavation_county ?? undefined,
    locateRemarks: resp.locate_remarks ?? undefined,
    type: resp.type ?? undefined,
    workDoneFor: resp.work_done_for ?? undefined,
    tlc_received_at: resp.tlc_received_at
      ? new Date(resp.tlc_received_at)
      : undefined,
    workStartDate: resp.work_start_date
      ? new Date(resp.work_start_date)
      : undefined,
    responseDueDate: resp.response_due_date
      ? new Date(resp.response_due_date)
      : undefined,
    reportedDate: resp.ticket_reported_date
      ? new Date(resp.ticket_reported_date)
      : undefined,
    crossStreet1: resp.cross_street_1 ?? undefined,
    crossStreet2: resp.cross_street_2 ?? undefined,
    scaled_impact_score: resp.scaled_impact_score,
    scaled_threat_score: resp.scaled_threat_score,
    zip_code: resp.zip_code ?? undefined,
    description: resp.description ?? undefined,
    locate_area: resp.locate_area ?? undefined,
    excavator_name: resp.excavator_name ?? undefined,
    excavator_phone_number: resp.excavator_phone_number ?? undefined,
  };
};

const respToFlexServiceCenter = (
  resp: FlexServiceCenterSerializer
): FlexServiceCenter => ({
  name: resp.name ?? undefined,
});

const respToFlexServiceCenters = (
  resp: FlexServiceCenterSerializer[]
): FlexServiceCenter[] =>
  resp.map((item) => ({
    id: item.id,
    name: item.name,
  }));

const respToFlexTask = (resp: FlexTaskSerializer): FlexTask => {
  const taskType: FlexTaskTypeSerializer | undefined =
    (resp as any).task_type || undefined;
  const taskStep: FlexTaskStepSerializer | undefined =
    (resp as any).task_step || undefined;
  const latestComment: FlexTaskCommentSerializer | undefined =
    (resp as any).latest_comment || undefined;
  const assignee: FlexUserSerializer | undefined =
    (resp as any).assignee || undefined;
  return {
    id: resp.id ?? undefined,
    sequence: resp.sequence ?? undefined,
    createTime: resp.create_time ? new Date(resp.create_time) : undefined,
    completedTime: resp.completed_time
      ? new Date(resp.completed_time)
      : undefined,
    assignee: assignee && {
      id: assignee.id ?? undefined,
      email: assignee.email ?? undefined,
      firstName: assignee.first_name ?? undefined,
      lastName: assignee.last_name ?? undefined,
      is_active: assignee.is_active ?? undefined,
    },
    taskType: taskType && {
      id: taskType.id ?? undefined,
      name: taskType.name ?? undefined,
    },
    taskStep: taskStep && {
      id: taskStep.id ?? undefined,
      name: taskStep.name ?? undefined,
      initial: taskStep.initial ?? undefined,
      terminating: taskStep.terminating ?? undefined,
    },
    latestComment: latestComment && {
      comment: latestComment.comment ?? undefined,
      created_at: latestComment.created_at ?? undefined,
      creator: latestComment.creator ?? undefined,
    },
  };
};

const respToFlexMemberCodes = (
  resp: FlexMemberCodesSerializer
): FlexMemberCodes => ({
  id: resp.id ?? undefined,
  key: resp.key ?? undefined,
});

const respToFlexTicket = (resp: FlexTicketSerializer): FlexTicket => {
  const latestComment: FlexTicketCommentSerializer | undefined =
    (resp as any).latest_comment || undefined;
  const tasks: FlexTaskSerializer[] | undefined =
    (resp as any).tasks || undefined;
  const memberCodesList: FlexMemberCodesSerializer[] | undefined =
    (resp as any).member_codes_list || undefined;
  return {
    id: resp.id,
    number: resp.number ?? undefined,
    expires_on: resp.expires_on ? new Date(resp.expires_on) : undefined,
    stage: resp.stage ?? undefined,
    latestVersion: resp.latest_version
      ? respToFlexTicketVersion(resp.latest_version)
      : undefined,
    latestComment: latestComment && respToFlexTicketComment(latestComment),
    serviceCenter: resp.service_center
      ? respToFlexServiceCenter(resp.service_center)
      : undefined,
    serviceCenters: resp.service_centers
      ? respToFlexServiceCenters(resp.service_centers)
      : undefined,
    tasks: tasks ? tasks.map(respToFlexTask) : undefined,
    memberCodesList: memberCodesList
      ? memberCodesList.map(respToFlexMemberCodes)
      : undefined,
    createTime: resp.create_time ? new Date(resp.create_time) : undefined,
    isEmergency: !!resp.is_emergency,
    positiveResponses: resp.positive_responses?.map(
      (positiveResponse) => new PositiveResponse(positiveResponse)
    ),
  };
};

export type {
  FlexTicketQueryFields,
  FlexTicket,
  FlexMemberCodes,
  FlexTask,
  FlexUser,
  FlexTaskType,
  FlexTaskStep,
  FlexServiceCenter,
  FlexTicketComment,
  FlexTicketVersion,
  FlexContact,
};
export { respToFlexTicket };
