import type { TicketTableRowProps } from "./TicketTableRow.types";
import classNames from "classnames";

const TicketTableRow = ({ row, height }: TicketTableRowProps) => {
  //
  // This could be optimized by changing our selection contexts to something
  // that's more granularly subscribable.
  //
  const { isSelected } = row.original;
  const rowProps = row.getRowProps({
    className: classNames(
      "_tr w-max bg-white flex w-auto items-center hover:bg-gray-20",
      { "bg-brand-10": isSelected }
    ),
  });
  return (
    <div
      className={rowProps.className}
      key={rowProps.key}
      role={rowProps.role}
      style={rowProps.style}
    >
      <div className="relative flex items-center w-full h-full bg-inherit">
        {isSelected && (
          <div
            className={classNames(
              "border-t border-b border-l-4 border-brand-40 w-full absolute flex-1 h-full z-10 pointer-events-none"
            )}
          />
        )}
        {row.cells.map((cell) => {
          const cellProps = cell.getCellProps({
            className: classNames(
              "_td bg-inherit mx-2 flex-grow flex h-full items-center py-1.5",
              (cell.column as any).className
            ),
            style: {
              minHeight: height,
              // Note: The tailwind supported `break-words` utility class doesn't work on Firefox, so here we are.
              wordBreak: "break-word",
            },
          });
          return (
            // consider using CSS line-clamp if text overrun continues to be an issue

            <div
              className={cellProps.className}
              key={cellProps.key}
              role={cellProps.role}
              style={cellProps.style}
              data-testid="table-cell"
            >
              {cell.render("Cell")}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { TicketTableRow };
